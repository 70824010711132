/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'info': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M11.965 5.558a5.75 5.75 0 00-1.94-4.033A6.09 6.09 0 005.719.005a6.058 6.058 0 00-4.15 1.886A5.72 5.72 0 00.006 6.076a5.515 5.515 0 00.717 2.862.92.92 0 01.06.75L.165 11.5a.231.231 0 00.061.253.247.247 0 00.263.047l1.665-.629a1.022 1.022 0 01.872.048 6.116 6.116 0 003.153.78 6.102 6.102 0 003.106-.94 5.866 5.866 0 002.14-2.382c.473-.971.66-2.05.54-3.118zM6.543 8.124a.477.477 0 01-.146.342.506.506 0 01-.704 0 .477.477 0 01-.146-.342V5.703c0-.129.052-.252.146-.343a.506.506 0 01.704 0c.094.091.146.214.146.343v2.421zm-.498-3.39a.509.509 0 01-.277-.081.488.488 0 01-.183-.218.472.472 0 01.108-.527.503.503 0 01.543-.105.495.495 0 01.223.178.475.475 0 01-.062.612.506.506 0 01-.352.141z" _fill="#898B9B"/>'
  }
})
